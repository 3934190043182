import React from "react"
import TermOfUse from "../sections/TermOfUse";

// function ScrollToTop() {
//   if (!window) return;
//   window.scrollTo(0, 0);
// }

export default function Terms({data}){
  // ScrollToTop();
    return(
        <section>
            <TermOfUse data={data.TermOfUse}/>
        </section>
    )
}