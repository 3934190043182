import { Container, makeStyles, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0px 0 496px 0",
    transition: "all .3s ease-in-out",
    "@media screen and (min-width: 410px)": {
      padding: "0px 0 477px 0",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "24px 24px 230px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px 24px 233px",
      width: "85%",
      margin: "0 auto",
    },
  },
  paper: {
    padding: "5% 7%",
    backgroundColor: theme.palette.background.paper,
    marginBottom: "40px",
    borderRadius: 0,
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
    },
  },
  title: {
    padding: "20px 0 10px",
    color: "#005CB2",
    fontSize: "20px",
    "@media screen and (min-width: 768px)": {
      fontSize: "25px",
      padding: "0 0 10px",
    },
    "@media screen and (min-width: 1024px)": {
      padding: "0 0 20px",
      fontSize: "30px",
    },
  },
  content: {
    margin: "0.5rem 0",
  },
}));

export default function TermOfUse({ data }) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          className={classes.title}
        >
          {data.title}
        </Typography>
        {data.description.map((desc, indexdesc) => (
          <Typography
            key={indexdesc}
            variant="body1"
            align="justify"
            className={classes.content}
          >
            {desc}
          </Typography>
        ))}
        <Typography>{data.sub_title}</Typography>
        <ul>
          {data.sub_description.map((subdesc, indexsubdesc) => (
            <li>
              <Typography
                key={indexsubdesc}
                variant="body1"
                align="justify"
                className={classes.content}
              >
                {subdesc}
              </Typography>
            </li>
          ))}
        </ul>
        {data.summary.map((item, indexsmr) => (
          <Typography
            key={indexsmr}
            variant="body1"
            align="justify"
            className={classes.content}
          >
            {item}
          </Typography>
        ))}
      </Paper>
    </Container>
  );
}
