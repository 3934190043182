import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/shell/seo"
import Layout from "../components/shell/layout"
import Terms from "../components/help/pageContents/Terms"

const TermofusePage = ({ data }) => (
  <Layout>
    <SEO title="Termofuse" />
    <Terms data={data.termsJson} />
  </Layout>
)

export default TermofusePage

export const query = graphql`
  {
    termsJson {
      TermOfUse {
        title
        sub_title
        description
        sub_description
        summary
      }
    }
  }
`
